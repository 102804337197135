"use client"

import { useState } from 'react'
import { Upload, X, FileIcon, CheckCircle, Copy } from 'lucide-react'
import { motion, AnimatePresence } from 'framer-motion'

export default function Component() {
  const [file, setFile] = useState<File | null>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isUploaded, setIsUploaded] = useState(false)
  const [downloadURL, setDownloadURL] = useState('')
  const [error, setError] = useState('')
  const [copySuccess, setCopySuccess] = useState(false)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    if (selectedFile) {
      if (validateFile(selectedFile)) {
        setFile(selectedFile)
        setError('')
      } else {
        setError('Invalid file type or size exceeds 100MB.')
        setFile(null)
      }
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(false)
    const droppedFile = event.dataTransfer.files[0]
    if (droppedFile) {
      if (validateFile(droppedFile)) {
        setFile(droppedFile)
        setError('')
      } else {
        setError('Invalid file type or size exceeds 100MB.')
        setFile(null)
      }
    }
  }

  const handleRemoveFile = () => {
    setFile(null)
    setIsUploaded(false)
    setDownloadURL('')
    setError('')
  }

  const handleUpload = async () => {
    if (!file) return

    setIsUploading(true)
    setError('')

    const formData = new FormData()
    formData.append('file', file)

    try {
      const response = await fetch(`/api/v1/upload`, {
        method: 'POST',
        body: formData,
      })

      if (response.ok) {
        const data = await response.json()
        setDownloadURL(`${window.location.origin}${data.url}`)
        setIsUploaded(true)
      } else {
        const errorData = await response.json()
        setError(errorData.detail || 'An error occurred during upload.')
      }
    } catch (err) {
      setError('Network error. Please try again.')
    } finally {
      setIsUploading(false)
    }
  }

  const validateFile = (file: File) => {
    const allowedTypes = ['application/zip']
    const maxSize = 100 * 1024 * 1024 // 100MB

    return allowedTypes.includes(file.type) && file.size <= maxSize
  }

  const handleCopyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(downloadURL)
        .then(() => {
          setCopySuccess(true)
          setTimeout(() => setCopySuccess(false), 2000)
        })
        .catch(() => {
          fallbackCopyTextToClipboard(downloadURL)
        })
    } else {
      fallbackCopyTextToClipboard(downloadURL)
    }
  }

  const fallbackCopyTextToClipboard = (text: string) => {
    const textArea = document.createElement("textarea")
    textArea.value = text
    textArea.style.position = "fixed"  // Avoid scrolling to bottom
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      document.execCommand('copy')
      setCopySuccess(true)
      setTimeout(() => setCopySuccess(false), 2000)
    } catch (err) {
      setError('Failed to copy the link.')
    }
    document.body.removeChild(textArea)
  }

  const handleUploadAnother = () => {
    handleRemoveFile()
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-gray-300 p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md p-8 bg-gray-900 rounded-2xl shadow-2xl relative overflow-hidden"
      >
        <div className="absolute inset-0 bg-gradient-to-br from-green-900/10 to-gray-900/10 z-0" />
        <div className="relative z-10">
          <motion.div
            className={`border-2 border-dashed rounded-xl p-8 text-center transition-colors duration-300 ${
              isDragging ? 'border-green-500 bg-gray-800' : 'border-gray-700'
            }`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.99 }}
          >
            {file ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="flex items-center justify-between bg-gray-800 p-4 rounded-lg"
              >
                <div className="flex items-center space-x-3">
                  <FileIcon size={24} className="text-green-400" />
                  <span className="truncate">{file.name}</span>
                </div>
                <button
                  onClick={handleRemoveFile}
                  className="text-gray-400 hover:text-red-400 transition-colors"
                >
                  <X size={20} />
                </button>
              </motion.div>
            ) : (
              <>
                <Upload size={48} className="mx-auto mb-4 text-green-400" />
                <p className="mb-4">Drag & Drop your ZIP file here or</p>
                <label
                  htmlFor="fileInput"
                  className="cursor-pointer bg-green-500 hover:bg-green-700 text-black font-medium py-2 px-4 rounded-lg transition-colors duration-300 inline-block"
                >
                  Choose File
                </label>
                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  onChange={handleFileChange}
                  accept=".zip"
                />
              </>
            )}
          </motion.div>
          <AnimatePresence>
            {error && (
              <motion.p
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="mt-4 text-red-500 text-center"
              >
                {error}
              </motion.p>
            )}
          </AnimatePresence>
          {!isUploaded && (
            <>
              <motion.button
                className={`w-full mt-6 font-medium py-3 px-4 rounded-lg transition-all duration-300 transform ${
                  file
                    ? 'bg-green-600 hover:bg-green-700 text-black'
                    : 'bg-gray-800 text-gray-400 cursor-not-allowed'
                }`}
                disabled={!file || isUploading}
                onClick={handleUpload}
                whileHover={{ scale: file ? 1.05 : 1 }}
                whileTap={{ scale: file ? 0.95 : 1 }}
              >
                {isUploading ? (
                  <span className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Uploading...
                  </span>
                ) : (
                  'Upload'
                )}
              </motion.button>
            </>
          )}
          <AnimatePresence>
            {isUploaded && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="mt-6 p-4 bg-gray-800 rounded-lg text-center"
              >
                <div className="flex items-center justify-center mb-2">
                  <CheckCircle size={24} className="text-green-400 mr-2" />
                  <p className="text-green-400">Upload Successful!</p>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    value={downloadURL}
                    readOnly
                    className="w-full p-2 mb-4 bg-gray-700 text-white rounded-lg pr-10"
                  />
                  <button
                    onClick={handleCopyLink}
                    className="absolute right-2 top-5 transform -translate-y-1/2 text-gray-400 hover:text-green-400 transition-colors"
                  >
                    <Copy size={20} />
                  </button>
                </div>
                <AnimatePresence>
                  {copySuccess && (
                    <motion.p
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="text-green-400 mb-2"
                    >
                      Link copied to clipboard!
                    </motion.p>
                  )}
                </AnimatePresence>
                <motion.button
                  onClick={handleUploadAnother}
                  className="w-full bg-green-600 hover:bg-green-700 text-black font-medium py-2 px-4 rounded-lg transition-colors duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Upload Another
                </motion.button>
              </motion.div>
            )}
          </AnimatePresence>
          <p className="mt-4 text-sm text-gray-500 text-center">
            Supported file types: <strong>ZIP</strong> only
          </p>
          <p className="mt-1 text-sm text-gray-500 text-center">
            Maximum file size: <strong>100MB</strong>
          </p>
        </div>
      </motion.div>
    </div>
  )
}
